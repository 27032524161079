@font-face {
  font-display: swap;
  font-family: 'Gotham Book';
  src: url('./../assets/fonts/gotham-book.woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Gotham Medium';
  src: url('./../assets/fonts/gotham-medium.woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Gotham Bold';
  src: url('./../assets/fonts/gotham-bold.woff2');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-gotham-medium);
  color: var(--gray-4);
}