@import 'variables';

mat-expansion-panel .mat-content {
  display: block !important;
}

.accordion-button {
  background: transparent;
  text-decoration: none;
  color: $dark;
  outline: none;
  width: 100%;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.text-bg-success {
  background-color: $green !important;
}

.mat-datepicker-content-touch .mat-datepicker-content-container {
  width: auto !important;
  height: auto !important;
}

.modal {
  z-index: 999999;
}

.filter-autocomplete-panel {
  border-radius: 16px !important;
  margin-top: 16px !important;
  max-height: 100% !important;
  padding: 16px 0;

  .mat-mdc-option {
    font-family: var(--font-gotham-book);
    min-height: 44px;
    padding: 8px 16px;

    svg {
      width: 24px;
      height: 24px;
      color: $dark;
      margin: 0 8px;
      flex-shrink: 0;

      &.icon-right-arrow {
        color: $red;
        display: none;

        @media (min-width: $xs) {
          display: block;
        }
      }
    }
  }

  .mdc-list-item__primary-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.cdk-overlay-backdrop-dark {
  background-color: var(--gray-4);
  opacity: 0.7 !important;
  transition: opacity 0.2s ease;
}
