@import './variables.scss';
@import './margin-padding.scss';
@import './input.scss';
@import './animations.scss';
@import './fonts.scss';


// regular style toast
@import 'node_modules/ngx-toastr/toastr';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~@angular/cdk/overlay-prebuilt.css';

:root {
  --white: #ffffff;
  --black: #000000;
  --gray-1: #e3e3e3;
  --gray-2: #b7b7b7;
  --gray-3: #818181;
  --gray-4: #3c3c3c;
  --gray-5: #1a1a1a;
  --gray-6: #f6f6f6;
  --gray-7: #f7f7f7;
  --gray-8: #e6e6e6;
  --gray-9: #e4e7eb;
  --gray-10: #3e4c59;
  --red-1: #ffd8db;
  --red-2: #f69da5;
  --red-3: #e45460;
  --red-4: #d30b1c;
  --red-5: #9b0814;
  --red-6: #de0b1c;
  --blue-1: #0d87ff;
  --feedback-alerta-100: #a5870e;
  --feedback-alerta-80: #edc72c;
  --feedback-alerta-60: #f8eec9;
  --feedback-error-100: #981515;
  --feedback-error-80: #e23131;
  --feedback-alerta-red-60: #ffdddd;
  --feedback-ok-100: #357935;
  --feedback-ok-80: #5cb85c;
  --feedback-ok-60: #dbffdf;
  --feedback-succes: #2e9a2e;
  --box-shadow-1: rgba(0, 0, 0, 0.25);
  --background-color-skeleton-animation-1: hsl(200, 20%, 80%);
  --background-color-skeleton-animation-2: hsl(200, 20%, 95%);
  --max-width-content-desktop: 596px;
  --light-green: #dbffdfff;
  --dark-green: #5cb85cff;
  --font-gotham-book: 'Gotham Book';
  --font-gotham-medium: 'Gotham Medium';
  --font-gotham-bold: 'Gotham Bold';
}

* {
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 992px) {
    &::-webkit-scrollbar {
      display: block;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 16px;
      background: var(--gray-3);
      border: 4px solid var(--gray-7);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--gray-4);
    }
  }
}

html,
body {
  background-color: $light-grey;
  font-family: 'Gotham Book';
  overflow: hidden;

  @media (max-width: $lg) {
    background-color: $white;
  }
}

button,
select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $dark;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}

:root,
body {
  font-size: 10px;

  @media (max-width: $xxxl) {
    font-size: 9px;
  }

  @media (max-width: $xxl) {
    font-size: 8px;
  }

  @media (max-width: $xl) {
    font-size: 7.5px;
  }

  @media (max-width: $lg) {
    font-size: 10px;
  }
}

svg {
  width: 3.5rem;
  height: 3.5rem;
}

/* custom columns for huge monitors */
@media (min-width: $xxxl) {
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

html {
  @media (max-width: $lg) {
    overflow: hidden;
  }
}

a,
a:hover {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

.strong {
  font-family: var(--font-gotham-bold);
}

.mid-strong {
  font-family: var(--font-gotham-medium);
}

a,
button {
  outline: none;

  &:focus {
    outline: none;
  }
}

li {
  outline: none;
}

.pointer {
  cursor: pointer;
}

.badge-pill {
  font-size: 1.4rem;
  padding: 0.8rem 1.5rem;

  @media (max-width: $lg) {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
  }

  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 1.2rem;
    padding: 0.5rem 1.2rem;
  }

  &.badge-warning {
    background-color: rgba(237, 199, 44, 0.3);
    color: $yellow;
  }

  &.badge-danger {
    background-color: rgba(255, 56, 56, 0.3);
    color: $red;
  }

  &.badge-success {
    background-color: rgba(70, 170, 66, 0.2);
    color: $green;
  }
}

app-my-benefits {
  width: 100%;
}

//Trick to prevent text highlight
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

// CARDS //
.card {
  border: none;
}

.ka-card {
  border: 1px solid $grey;
  border-radius: 9.46524px;
}

.border-radius {
  border-radius: 15px !important;
}

.ka-grey-card {
  width: 100%;
  background: $light-grey;
  border-radius: 10px;
  padding: 1rem;
}

.status {
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 5px 10px;
  text-align: center;
  max-width: 150px;
  min-width: 80%;
  display: inline-block;

  &.CREATED,
  &.DELIVERED,
  &.FAILED,
  &.REGISTERED,
  &.PREPARING,
  &.TRANSIT {
    color: $yellow;
    font-weight: bold;
  }

  &.DELIVERED_CLT {
    color: $green;
    font-weight: bold;
  }

  &.RETURNED,
  &.BLOCKED,
  &.CANCELLATION_REQUEST {
    color: $red;
    font-weight: bold;
  }

  &.CANCELLED {
    background-color: transparent;
    color: $dark;
    font-weight: bold;
  }
}

/* OWL carousel */
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 36%;
  width: 3.5rem;
  height: 3.5rem;
  background: $red;
  font-size: 25px;
  font-family: var(--font-gotham-bold);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  &.disabled {
    display: none;
  }

  svg {
    color: $white;
  }

  @media (min-width: $lg) and (max-width: $xl) {
    top: 30% !important;
  }
}

.owl-carousel .owl-nav .owl-prev {
  left: -1.2rem;
}

.owl-carousel .owl-nav .owl-next {
  right: -1.2rem;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background-color: $dark-red;
}

@media (max-width: $lg) {
  .owl-carousel .owl-item img {
    max-width: 100%;
    width: auto !important;
  }
}

/* BUTTONS */

.button-principal {
  padding: 10px;
  border: 2px solid $dark;
  border-radius: 3px;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.7rem;
  max-width: 375px;
  outline: none;

  @media (max-width: $lg) {
    font-size: 1.2rem;
  }
}

.btn-transparent {
  @extend .button-principal;
  background-color: transparent;
  color: $dark;

  p {
    font-size: medium;
  }

  &:hover {
    color: $red;
  }

  &:disabled {
    color: $dark-grey;
    border: 2px solid $dark-grey;
    opacity: 0.9;
  }
}

.btn-black {
  @extend .button-principal;
  background-color: $dark;
  color: $white;

  &:disabled {
    color: $dark-grey-bold;
    background-color: $shadow-grey !important;
    opacity: 0.9;
  }
}

.btn-white {
  @extend .button-principal;
  background-color: $white;
  color: $dark;
}

.btn-white-bordered {
  @extend .button-principal;
  background-color: $white;
  border: 1px solid $black !important;
  color: $dark;
  &:disabled {
    background-color: $dark-grey;
    color: $white;
    opacity: 0.9;
    border: unset !important;
  }
}

.btn-outline-red {
  @extend .button-principal;
  background-color: transparent;
  color: $red;
  border: 1px solid $red;
  border-radius: 6px;
  max-width: 100%;
  font-size: 1.4rem !important;
  line-height: 1.6rem;

  &:hover {
    border: 1px solid $dark-red-secondary;
    color: $dark-red-secondary;
  }

  span {
    font-family: var(--font-gotham-medium);
  }
}

.btn-square {
  font-size: 1.4rem;
  max-width: 375px;
  padding: 0.6rem 1rem;
  width: 100%;
  border: 1.5px solid $dark;
  border-radius: 3px;
  background-color: $white;
  color: $black;
  outline: none !important;

  @media (max-width: $lg) {
    font-size: 1.2rem;
  }
}

.btn-square-red {
  @extend .btn-square;
  background-color: $red;
  color: $white;

  &:disabled {
    background-color: $dark-grey;
    color: $white;
    border: 1.5px solid $dark-grey;
    opacity: 0.9;
  }
}

.btn-square-black {
  @extend .btn-square;
  background-color: $black;
  color: $white;

  &:disabled {
    background-color: $dark-grey;
    color: $white;
    border: 1.5px solid $dark-grey;
    opacity: 0.9;
  }
}

.btn-square-green {
  @extend .btn-square;
  background-color: $green;
  color: $white;

  &:disabled {
    background-color: $green;
    color: $white;
    border: 1.5px solid $green;
  }
}

.btn-pill,
.btn-pill-add,
.subunits-input {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $white;
  font-family: var(--font-gotham-medium);
  border-radius: 6px;
  border: 1px solid $border-grey-button;
  text-align: center;
  color: $red;
  font-size: 1.5rem;
  line-height: 1.6rem;

  span,
  input {
    padding: 1rem 0;
    font-size: 1.4rem !important;
    width: 2.4rem;
  }

  input {
    margin: 0 0.5rem;
    width: 3rem;
    text-align: center;
    color: $dark;
    background-color: transparent;
    font-family: var(--font-gotham-medium);
    border: none;

    &.error {
      box-shadow: 0 0 5px 0px $red;
    }
  }

  span {
    cursor: pointer;
    color: $red;
    width: 3rem;
    height: 2rem;
    &.disabled {
      color: $dark-grey-bold;
    }
  }

  &:disabled {
    color: $dark-grey-bold;
  }
}

.subunits {
  color: $dark;
  font-family: var(--font-gotham-book);
}

.dropdown-subunits .filter-selected {
  color: $dark !important;
  background-color: $white !important;
  border: 1px solid $background-grey !important;
  svg {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.call-to-action {
  @extend .btn;
  font-size: 1.8rem;

  @media (max-width: $xxl) {
    font-size: 1.6rem;
  }

  img {
    width: 2.2rem;
    margin-right: 1rem;
    vertical-align: sub;
  }
}

.btn-chevron {
  font-size: 1.4rem;
  width: auto;
  padding: 0.6rem 1rem;
  border: none;
  background-color: transparent;
  outline: none !important;
  @extend .d-flex;
  @extend .align-items-center;

  svg {
    color: $red;
    width: 22px;
  }
}

/// TITLES ///

.title-h1 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 28px;
  white-space: pre-line;
}

.title-h2 {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 28px;
  word-break: break-all;
}

.title-h3 {
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 2.8rem;

  @media (max-width: $lg) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.title-main {
  font-family: var(--font-gotham-medium);
  font-size: 2rem !important;
  line-height: 2.5rem;
  text-align: left;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  align-self: flex-start;

  @media screen and (max-width: $lg) {
    font-size: 16px !important;
    line-height: 19px;
    margin-top: 1rem;
  }
}

.subtitle-main {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: $dark;
  align-self: flex-start;
  margin-bottom: 1.5rem;
}

// LINKS //

.dark-link {
  color: $dark;
  cursor: pointer;
}

.grey-link {
  color: #a1a1a1;
  cursor: pointer;
}

.blue-link {
  color: $blue;
  cursor: pointer;
  background: none !important;
  border: none !important;
}

.blue-link-bold {
  @extend .blue-link;
  font-family: var(--font-gotham-medium);
}

.red-link {
  cursor: pointer;
  background: none !important;
  border: none !important;
  margin-bottom: 1rem;

  p {
    color: $red;
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  svg {
    margin-top: -3px;
    color: $red;
    width: 2rem;
  }
}

// ALERTS //
.alert {
  padding: 1rem;
  font-size: 1.5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;

  &.red-alert,
  &.alert-danger {
    background: $light-red;
    color: $dark-red;
  }

  &.alert-warning {
    .btn-warning {
      background-color: #a5870e !important;
      border: 1px solid #a5870e;
      color: #f8eec9;
      padding: 1rem 1.5rem;
      font-size: 1.4rem;
    }
  }

  svg {
    width: 3rem;
    height: 3rem;
    margin: 0 1rem;
  }
}

.info-alert {
  color: $dark-red;
  border-radius: 9px;
  padding: 1rem;
  font-size: 1.4rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;

  svg {
    width: 3rem;
    height: 3rem;
    margin: 0 1rem;
  }
}

.bg-grey {
  background-color: $light-grey;
}

.text-muted {
  color: $dark-grey !important;

  &:hover {
    background-color: transparent !important;
  }
}

// OVERLAYS //
.login-client-num-popover {
  width: 400px;
  max-width: 400px;

  img {
    width: 100%;
  }

  @media screen and (max-width: $md) {
    width: 80%;
    max-width: 80%;
    margin-left: -15%;
  }

  @media screen and (max-width: $lg) {
    width: 80%;
    max-width: 80%;
    margin-left: -20%;
  }
}

mat-icon {
  font-family: $font-material-icons !important;
}

.mat-expansion-panel-header {
  padding: 0 1.5rem !important;
  &.mat-expanded {
    height: 4.5rem !important;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-datepicker-content {
  @media (max-width: $sm) {
    @supports (-webkit-touch-callout: none) {
      .mat-calendar {
        max-width: 242px;
        max-height: 300px;
      }
    }
  }

  @media screen and (min-width: $sm) {
    left: 123px;
    top: 25px;
    position: relative;
  }
}

.mat-calendar-body-selected {
  background-color: $red;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: $light-red;
}

.mat-datepicker-popup {
  @media screen and (max-width: $sm) {
    left: 50% !important;
    transform: translate(-50%) !important;
  }
}

@media (max-width: $lg) {
  -webkit-scrollbar {
    display: none;
  }
}

// COMPONENT MODEL //

.ka-sidebar {
  height: 100vh;
  width: 100%;
  background-color: $white;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-sidebar {
  background: $light-grey;

  @media (max-width: $lg) {
    background-color: $white;
  }

  border-radius: 10px;
  padding: 2rem 1.5rem;

  p,
  span {
    font-size: 1.4rem;

    @media (max-width: $lg) {
      font-size: 1.2rem;
    }
  }
}

// OTHERS //

.text-error {
  color: $red;
  display: block;
  font-size: 1.1rem !important;
}

.toast-container {
  opacity: 0.9;

  .ngx-toastr {
    border: none;
    font-size: 1.4rem;
  }

  .toast-error {
    background-color: #c82333;
  }

  .toast-success {
    background-color: green;
  }

  .toast-warning {
    background-color: $light-yellow;
    color: $dark-grey-bold !important;
    background-image: url('./../assets/icons/alert-icon.png') !important;

    .toast-title {
      font-size: var(--font-gotham-medium);
    }
  }
}

.red-circle {
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  border-radius: 100%;
  background-color: $red;
  display: flex;
  justify-content: center;
  align-items: center;
}

// MODALS //

.modal-dialog {
  @media (min-width: $sm) and (max-width: $lg) {
    max-width: 90% !important;
  }
}

.ngbmodal-centered .modal-dialog {
  @extend .modal-dialog-centered;
}

.modal-content {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

  .modal-header {
    border: none !important;
    padding-bottom: 0 !important;

    button {
      padding: 1rem 2rem !important;

      span {
        font-size: 2.5rem;
      }
    }
  }

  .modal-body {
    text-align: center;
    font-family: var(--font-gotham-medium);
    padding-bottom: 2rem;

    p {
      font-size: 1.4rem;

      @media (max-width: $sm) {
        font-size: 1.3rem;
      }
    }
  }

  .modal-footer {
    border: none !important;
    padding-bottom: 4rem;

    #add-scale-products {
      @media (max-width: $sm) {
        width: 100% !important;
      }
    }
  }
}

//MODAL PRODUCT SCALE
.open-modal-product-scale {
  cursor: pointer;
}

.open-modal-product-scale span {
  color: $blue;
  padding-left: 7px;
  font-size: 1.5rem !important;
}

.open-modal-product-scale svg {
  color: $blue;
  width: 15px;
  height: 15px;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;

  .mat-option {
    font-size: 1.5rem !important;
  }
}

// TOOLTIPS //
.custom-tooltip .tooltip-inner,
.menu-tooltip {
  font-size: 1.4rem !important;
}

.tooltip-product-max-qty .tooltip-inner {
  max-width: 100px !important;
  font-size: 1.4rem !important;
}

.tooltip-input-qty {
  max-width: 150px;
}

// Hide EDGE password automatic behaviour
input::-ms-reveal {
  display: none;
}

#qservus-widget-container {
  display: none;

  .qs-modal-dialog {
    width: 800px !important;
    height: 550px !important;

    @media (max-width: $lg) {
      width: 90% !important;
      height: auto !important;
    }
  }
}

//NEW LOGIN
.login {
  height: 100%;
  .login-center-flex {
    @media (min-width: $lg) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .border-top-grey {
    border-top: 2px solid $border-grey-secondary;
  }

  .custom-title-main {
    font-size: 16px !important;
  }

  .custom-subtitle-main {
    font-size: 14px !important;
  }

  .login-logoandina {
    width: 120px;
    height: 45px;
  }

  .blue-link-medium {
    @extend .blue-link;
    font-family: var(--font-gotham-medium);
    font-size: 16px !important;
    cursor: pointer;
  }

  .toggle-pwd {
    cursor: pointer;
    width: 4rem;
    height: 40px;
    outline: none;
    color: $dark-grey;
    padding: 8px;
    border: 1px solid $dark-grey;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: unset;

    &.error {
      border-color: $red !important;
    }
  }

  .logo-contain {
    position: fixed;
    background: $white;
    height: 6rem;
    z-index: 999;
  }

  .custom-padding-top {
    padding-top: 8rem;
  }

  .input-div {
    border: 1px solid $border-grey-secondary !important;
  }

  .alert-ul {
    margin: 0;
    padding-left: 16px;
    font-size: 14px;
  }

  #container-errors span {
    font-size: 12px;
    color: $dark-red;
  }

  .invalid-info {
    font-family: var(--font-gotham-medium);
  }

  .custom-back-button {
    @extend .blue-link-medium;
    letter-spacing: 0.5px;
    font-size: 17px !important;
  }

  #volver-a-enviar {
    font-family: var(--font-gotham-medium);
  }
}

.categories-carousel,
.home-suggested-carousel {
  .owl-theme .owl-nav {
    margin-top: 0;
  }
}

.suggested-on-carousel {
  .owl-theme .owl-nav {
    display: none !important;

    @media (max-width: $lg) {
      display: block !important;
      margin-top: 0 !important;
    }
  }

  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    @media (max-width: $md) {
      top: calc(70px - 3.5vw) !important;
      width: 7vw;
      height: 7vw;
      max-width: 35px;
      max-height: 35px;

      img {
        width: 9px;
      }
    }

    @media (min-width: $md) and (max-width: $lg) {
      top: 42.5px !important;
    }
  }
}

//DISCOUNTS AND RETURNABILITY FILTERS
.filter-discount-returnability-btn {
  border-radius: 10rem;
  background: $light-grey;
  font-size: 1.4rem;
  border: unset;

  @media (max-width: $lg) {
    font-size: 1.2rem;
  }
}

.filter-discount-returnability-btn svg {
  width: 2rem;
  height: 3.5rem;
}

.filter-active {
  background: $red;
  color: $white;
}

//BOTTOM MODAL HISTORY ORDER
.mat-bottom-sheet-history {
  font-size: 1.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

button:disabled {
  pointer-events: auto;
}

.mat-bottom-sheet-container {
  padding: 0px !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.pagination {
  label,
  span {
    font-size: 1.4rem;
    @media (max-width: $lg) {
      font-size: 1.2rem;
    }
  }
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    &:last-child .page-link {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    &:last-child .page-link,
    &:first-child .page-link {
      padding: 0.65rem;
      @media (max-width: $lg) {
        padding: 0.6rem;
      }
      @media (max-width: $sm) {
        padding: 0.45rem;
      }
      @media (min-width: $xxl) {
        padding: 0.7rem;
      }
    }
    &.disabled .page-link {
      background-color: $white;
    }
    &.disabled {
      &:last-child .page-link,
      &:first-child .page-link {
        background-color: $dark-grey !important;
        svg {
          color: $white;
        }
      }
    }
  }
  .page-link {
    color: $dark !important;
    font-family: var(--font-gotham-book);
    font-size: 1.25rem;
    @media (max-width: $lg) {
      font-size: 1rem;
    }
    @media (max-width: $sm) {
      padding: 0.5rem 1rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
      @media (max-width: $lg) {
        width: 1.8rem;
        height: 1.8rem;
      }
      @media (max-width: $sm) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: $white;
    }
  }
  .page-item.active .page-link {
    z-index: 1 !important;
    background-color: $dark !important;
    border-color: $grey !important;
    color: $white !important;
  }
}

.pagination {
  label,
  span {
    font-size: 1.4rem;
    @media (max-width: $lg) {
      font-size: 1.2rem;
    }
  }
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    &:last-child .page-link {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    &:last-child .page-link,
    &:first-child .page-link {
      padding: 0.65rem;
      @media (max-width: $lg) {
        padding: 0.6rem;
      }
      @media (max-width: $sm) {
        padding: 0.45rem;
      }
      @media (min-width: $xxl) {
        padding: 0.7rem;
      }
    }
    &.disabled .page-link {
      background-color: $white;
    }
    &.disabled {
      &:last-child .page-link,
      &:first-child .page-link {
        background-color: $dark-grey !important;
        svg {
          color: $white;
        }
      }
    }
  }
  .page-link {
    color: $dark !important;
    font-family: var(--font-gotham-book);
    font-size: 1.25rem;
    @media (max-width: $lg) {
      font-size: 1rem;
    }
    @media (max-width: $sm) {
      padding: 0.5rem 1rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
      @media (max-width: $lg) {
        width: 1.8rem;
        height: 1.8rem;
      }
      @media (max-width: $sm) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: $white;
    }
  }
  .page-item.active .page-link {
    z-index: 1 !important;
    background-color: $dark !important;
    border-color: $grey !important;
    color: $white !important;
  }
}

.stock-digital-modal .modal-dialog {
  max-width: 415px;
}
.modal-backdrop {
  z-index: 1040 !important;
}

app-new-ticket {
  app-card {
    app-text-area {
      textarea {
        height: 120px;
      }
    }
  }
  @media (min-width: 992px) {
    app-card {
      app-text-area {
        textarea {
          height: 400px;
        }
      }
    }
  }
}

.survey-ar .modal-content {
  height: 60vh;
}

.select-visit-date-desktop .modal-content {
  min-width: max-content; 
}

@import 'material-overrides';

.btn-close {
  float: inline-end;

  &:focus {
    box-shadow: none;
  }
}
 